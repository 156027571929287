<template>
  <v-row v-show="show">
    <v-col cols="12" class="pa-0">
      <v-avatar v-show="!overlay" class="closeIcon" color="" size="40"> </v-avatar>

      <!-- <div class="text-center butler-700 font-16 pt-6 hidden-md-and-up">
              {{ customizations.product.name }}
              <div class="text-center butler-gray font-16">
                by {{ customizations.product.vendor.brandName }}
              </div>
            </div> -->
      <!-- <v-row>
        <div class="text-center realref justify-space-around">
          <div class="text-center mt-4">
            <img src="@/assets/Images/resetting.svg" alt="cart" />
          </div>
          <div class="icon-image text-center mt-7">
            <img src="@/assets/Images/rounded.svg" class="icon-image" alt="cart" />
            <span class="font-12 sub-icon-img">
              <img src="@/assets/Images/camera-white.svg" alt="cart" width="28px" />
            </span>
          </div>
          <div class="icon-image text-center pt-7">
            <img src="@/assets/Images/customQuestion.svg" class="icon-image" alt="cart" />
          </div>
          <div
            class="icon-image text-center pt-7"
            v-on:click="buttonHide = !buttonHide"
            v-if="buttonHide"
          >
            <img src="@/assets/Images/rounded.svg" class="icon-image" alt="cart" />
            <span class="font-12 poppins-bold sub-icon-img pt-5"> Size </span>
          </div>
          <div class="btnGroup-section ml-3 mt-7" v-if="!buttonHide">
            <div
              class="font-12 text-maroon pt-5 poppins-bold"
              v-on:click="buttonHide = !buttonHide"
            >
              Size
            </div>
            <v-btn
              height="fit-content"
              @click="toggleSizeBtn('Normal')"
              class="text-transform-none poppins font-9 mt-2"
              :class="selectedSize == 'Normal' ? 'dark-btn' : 'light-btn'"
            >
              <img src="@/assets/Images/small.svg" class="" alt="cart"
            /></v-btn>

            <v-btn
              min-width="0"
              height="fit-content"
              @click="toggleSizeBtn('Plus')"
              class="text-transform-none poppins font-9 mt-2 mb-5"
              :class="selectedSize == 'Plus' ? 'dark-btn' : 'light-btn'"
            >
              <img src="@/assets/Images/xl.svg" alt="cart" />
            </v-btn>
          </div>
        </div>
      </v-row> -->
      <div v-show="!overlay" class="model-content">
        <div class="col py-0 px-0">
          <v-row>
            <v-col class="py-0 px-0">
              <div class="image-preview">
                <div style="height: 465px" class="image-preview-body">
                  <div
                    class="category text-center"
                    v-for="(category, index) in $store.state.customization.categories"
                    v-bind:key="index"
                    :class="category.name"
                  >
                    <div
                      v-for="(layer, index) in category.layers"
                      v-bind:key="index"
                      :class="layer.name"
                    >
                      <div
                        v-if="
                          layer &&
                          layer.toggles &&
                          layer.toggles.length > 0 &&
                          layer.toggles[layer.selectedToggle] &&
                          layer.typeOfLayer === 'Image'
                        "
                      >
                        <div
                          v-for="image in layer.toggles[layer.selectedToggle].baseTypes[
                            fetchBaseTypeIndexAsPerColorMapping(
                              layer.toggles[layer.selectedToggle],
                              category
                            )
                          ].toggleImageBody"
                          :key="image.index"
                        >
                          <div>
                            <div v-if="showFilter[layer.id]">
                              <img
                                :class="
                                  selectedSize == 'Normal' ? 'model-image' : 'model-image-plus'
                                "
                                v-if="
                                  layer.typeOfLayer == 'Image' &&
                                  selectedSize + ' ' + direction === image.name
                                "
                                v-bind:style="{
                                  filter: `saturate(${
                                    colorObj[layer.id] && colorObj[layer.id].saturation
                                      ? colorObj[layer.id].saturation
                                      : ''
                                  }) opacity(${
                                    colorObj[layer.id] && colorObj[layer.id].opacity
                                      ? colorObj[layer.id].opacity
                                      : ''
                                  }) hue-rotate(${
                                    colorObj[layer.id] && colorObj[layer.id].hue
                                      ? colorObj[layer.id].hue
                                      : ''
                                  }deg) brightness(${
                                    colorObj[layer.id] && colorObj[layer.id].brightness
                                      ? colorObj[layer.id].brightness
                                      : ''
                                  })`,
                                }"
                                :src="generateCompressImgkitURL(image.image, '550', '900')"
                              />
                            </div>
                            <div v-else>
                              <img
                                :class="
                                  selectedSize == 'Normal' ? 'model-image' : 'model-image-plus'
                                "
                                v-if="
                                  layer.typeOfLayer == 'Image' &&
                                  selectedSize + ' ' + direction === image.name
                                "
                                :src="generateCompressImgkitURL(image.image, '550', '900')"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="
                          layer &&
                          layer.toggles &&
                          layer.toggles.length > 0 &&
                          layer.toggles[layer.selectedToggle]
                        "
                      >
                        <div
                          v-for="embroideryObj in layer.toggles[layer.selectedToggle].baseTypes[
                            fetchBaseTypeIndexAsPerColorMappingEmb(
                              layer.toggles[layer.selectedToggle]
                            )
                          ].embroidery"
                          :key="embroideryObj.index"
                        >
                          <div v-if="category.selectedEmbroidery == embroideryObj.id">
                            <div v-for="embroidery in embroideryObj.images" :key="embroidery.index">
                              <img
                                :class="
                                  selectedSize == 'Normal' ? 'model-image' : 'model-image-plus'
                                "
                                v-if="
                                  layer.typeOfLayer == 'Image' &&
                                  selectedSize + ' ' + direction === embroidery.name &&
                                  embroidery.image !== 'IMG VALUE'
                                "
                                v-bind:style="{
                                  filter: `saturate(${
                                    embColorObj[layer.id] && embColorObj[layer.id].saturation
                                      ? embColorObj[layer.id].saturation
                                      : ''
                                  }) opacity(${
                                    embColorObj[layer.id] && embColorObj[layer.id].opacity
                                      ? embColorObj[layer.id].opacity
                                      : ''
                                  }) hue-rotate(${
                                    embColorObj[layer.id] && embColorObj[layer.id].hue
                                      ? embColorObj[layer.id].hue
                                      : ''
                                  }deg) brightness(${
                                    embColorObj[layer.id] && embColorObj[layer.id].brightness
                                      ? embColorObj[layer.id].brightness
                                      : ''
                                  })`,
                                }"
                                :src="generateCompressImgkitURL(embroidery.image, '550', '900')"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>

      <div v-if="$route.path == `/customization/step4/${this.$route.params.id}`">
        <v-row no-gutters class="justify-center">
          <v-col cols="6" align="center">
            <div class="table-area table-responsive">
              <table class="table border">
                <tbody>
                  <tr>
                    <td>Price</td>
                    <td>
                      <div class="text-center font-16 poppins-medium" v-if="productPrice">
                        {{ this.productPrice | currency }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Add on</td>
                    <td>
                      <div class="text-center font-16 poppins-medium" v-if="finalPrice">
                        {{ this.finalPrice | currency }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Total</td>
                    <td>
                      <div
                        class="text-center font-16 poppins-medium"
                        v-if="finalPrice && productPrice"
                      >
                        {{ (this.finalPrice + this.productPrice) | currency }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="text-center font-16 poppins-medium" v-else>
        <div v-if="productPrice">{{ this.productPrice | currency }}</div>
      </div>

      <div class="size-direction-section">
        <div class="">
          <!-- <div class="font-12 poppins-grey-500 text-center">
                  Front/Back
                </div> -->
          <div class="justify-center mt-3" align="center">
            <img @click="changeDirection()" src="@/assets/Images/frontback.svg" alt="reset" />
          </div>
          <div
            class="icon-image mt-5"
            @click="selectedSize == 'Normal' ? toggleSizeBtn('Plus') : toggleSizeBtn('Normal')"
          >
            <img src="@/assets/Images/rounded.svg" class="icon-image" alt="cart" />
            <span class="font-12 poppins-bold sub-icon-img pb-1"> Size </span>
          </div>
        </div>
      </div>
    </v-col>

    <div class="mt-3 justify-center" align="center">
      <v-card class="customize-card" flat>
        <v-row>
          <v-tabs
            fixed-tabs
            v-model="selectedCategory"
            class="justify-center pa-0 tabs-section"
            align="center"
            height="29px"
          >
            <v-tabs-slider color="#72593F" class="tabs-slider-none"></v-tabs-slider>
            <v-tab
              @click="selectedLayer = 0"
              v-for="(category, index) in customization.categories"
              :key="category.index"
              :href="'#' + index"
              class="font-16 poppins-medium px-0"
            >
              {{ category.name }}
            </v-tab>
          </v-tabs>
        </v-row>
      </v-card>
    </div>

    <div class="options mt-8 d-flex pb-2">
      <v-card
        @click="customization.categories[selectedCategory].selectedLayer = index"
        width="100px"
        v-for="(layer, index) in inNavigationLayers"
        :key="index"
        class="px-5 mr-5 text-center"
        :class="
          customization.categories[selectedCategory].selectedLayer == index
            ? 'active-layer-section'
            : 'inactive-layer-section'
        "
      >
        <div
          class="justify-center"
          align="center"
          :style="
            customization.categories[selectedCategory].selectedLayer == index
              ? 'filter: brightness(0) !important;'
              : ''
          "
        >
          <div class="options-container justify-center">
            <img :src="layer.icon" class="size-img" />
          </div>
          <div class="title-text poppins-bold font-10" @click="getLayer(layer)">
            {{ layer.name }}
          </div>
        </div>
      </v-card>
    </div>
    <div
      style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important"
      class="sub-option d-flex py-1 mt-1"
      v-if="
        customization &&
        customization.categories &&
        customization.categories.length > 0 &&
        customization.categories[selectedCategory].layers.length > 0 &&
        customization.categories[selectedCategory].layers[
          customization.categories[selectedCategory].selectedLayer
        ].toggles.length > 0
      "
    >
      <div
        @click="
          (customization.categories[selectedCategory].layers[
            customization.categories[selectedCategory].selectedLayer
          ].selectedToggle = index),
            toggleChange(),
            customization.categories[selectedCategory].layers[
              customization.categories[selectedCategory].selectedLayer
            ].typeOfLayer == 'Embroidery'
              ? changeEmbroidery(
                  customization.categories[selectedCategory].layers[
                    customization.categories[selectedCategory].selectedLayer
                  ].embroideryMapping,
                  toggle.name,
                  toggle.id
                )
              : customization.categories[selectedCategory].layers[
                  customization.categories[selectedCategory].selectedLayer
                ].typeOfLayer == 'Color'
              ? changeColor(
                  customization.categories[selectedCategory].layers[
                    customization.categories[selectedCategory].selectedLayer
                  ].colorMapping,
                  toggle.colorAssignment
                )
              : customization.categories[selectedCategory].layers[
                  customization.categories[selectedCategory].selectedLayer
                ].typeOfLayer == 'Embroidery Color'
              ? changeEmbroideryColor(
                  customization.categories[selectedCategory].layers[
                    customization.categories[selectedCategory].selectedLayer
                  ].colorMapping,
                  toggle.colorAssignment
                )
              : ''
        "
        v-for="(toggle, index) in customization.categories[selectedCategory].layers[
          customization.categories[selectedCategory].selectedLayer
        ].toggles"
        :key="toggle.index"
        :class="selectedToggle != index && !toggle.icon ? '' : ''"
      >
        <div class="selected-img mt-2 py-1" :class="[index == 0 ? 'ml-6 mr-3 mt-1' : 'mx-3']">
          <v-card flat>
            <div
              v-if="toggle.hex && toggle.hex.length > 0"
              :class="
                customization.categories[selectedCategory].layers[
                  customization.categories[selectedCategory].selectedLayer
                ].selectedToggle == index
                  ? 'active-option-shade-color '
                  : 'inactive-option-shade'
              "
              class="sub-option-img text-center"
              style="border-radius: 2px"
            >
              <div
                class="rounded-color mr-1"
                :style="'background-color: ' + toggle.hex[0].color"
              ></div>
            </div>
            <div
              v-else-if="toggle.icon"
              :class="
                customization.categories[selectedCategory].layers[
                  customization.categories[selectedCategory].selectedLayer
                ].selectedToggle == index
                  ? 'active-option-shade'
                  : 'inactive-option-shade'
              "
              class="sub-option-img text-center"
              style="border-radius: 2px"
            >
              <img :src="toggle.icon" alt="" class="" />
            </div>
            <div
              v-else
              :class="
                customization.categories[selectedCategory].layers[
                  customization.categories[selectedCategory].selectedLayer
                ].selectedToggle == index
                  ? 'active-option-shade'
                  : 'inactive-option-shade'
              "
              class="sub-option-img text-center"
              style="border-radius: 2px"
            >
              <!-- {{ toggle.name }} -->
              <v-img :src="textToIconGenerator(toggle.name)"></v-img>
            </div>
          </v-card>
        </div>
      </div>
    </div>
  </v-row>
</template>

<script>
import _ from 'lodash';
import service from '@/service/apiService';
import { SVG } from '@svgdotjs/svg.js';

export default {
  props: ['show'],
  data() {
    return {
      showFilter: {},
      colorObj: {},
      embColorObj: {},
      customizationsLoaded: false,
      buttonHide: true,
      overlay: false,
      selectedSize: 'Normal',
      direction: 'Front',
      selectedCategory: 0,
      colorSelection: '',
      embroiderySelection: '',
      productData: {},
      selectedToggle: 0,
      selectedToggleImageSide: '',
      selectedEmbName: '',
      selectedLayer: 0,
      finalPrice: 0,
      productPrice: 0,
      lastSelectedColorLayer: '',
    };
  },
  async created() {
    this.productPrice = this.$store.state.productPrice;
    /* eslint-disable */
    // _.each(this.customization.categories, (category) => {
    //   _.each(category.layers, (layer) => {
    //     if (layer.typeOfLayer == 'Color') {
    //       this.changeColor(layer.colorMapping, layer.toggles[0].colorAssignment);
    //     } else if (layer.typeOfLayer == 'Embroidery') {
    //       this.changeEmbroidery(layer.embroideryMapping, layer.toggles[layer.selectedToggle].name);
    //     } else if (layer.typeOfLayer == 'Embroidery Color') {
    //       this.changeEmbroideryColor(layer.colorMapping, layer.toggles[0].colorAssignment);
    //     }
    //   });
    // });

    await this.changeColor(
      this.customization.categories[this.selectedCategory].layers[this.selectedLayer].colorMapping,
      this.customization.categories[this.selectedCategory].layers[this.selectedLayer].toggles[
        this.selectedToggle
      ].colorAssignment
    );
    _.each(this.customization.categories, (category, index) => {
      _.each(category.layers, (layer) => {
        if (index == 0) {
          this.selectedToggle = layer.selectedToggle;
        }
        if (layer.typeOfLayer == 'Color') {
          if (
            layer &&
            layer.toggles &&
            layer.toggles.length > 0 &&
            layer.toggles[this.selectedToggle] &&
            layer.toggles[this.selectedToggle].colorAssignment &&
            layer.toggles[this.selectedToggle].colorAssignment.length > 0
          ) {
            this.lastSelectedColorLayer = layer.id;
            this.changeColor(
              layer.colorMapping,
              layer.toggles[this.selectedToggle].colorAssignment
            );
          }
        } else if (layer.typeOfLayer == 'Embroidery') {
          if (layer && layer.toggles && layer.toggles.length > 0) {
            console.log('layer.toggles', layer.toggles);
            console.log('this.selectedToggle', this.selectedToggle);

            this.changeEmbroidery(
              layer.embroideryMapping,
              layer.toggles[layer.selectedToggle].name,
              layer.toggles[layer.selectedToggle].id
            );
          }
        } else if (layer.typeOfLayer == 'Embroidery Color') {
          if (
            layer &&
            layer.toggles &&
            layer.toggles.length > 0 &&
            layer.toggles[
              this.selectedToggle && layer.toggles[this.selectedToggle].colorAssignment.length > 0
            ]
          )
            this.changeEmbroideryColor(
              layer.colorMapping,
              layer.toggles[this.selectedToggle].colorAssignment
            );
        }
      });
    });
    await this.changeEmbroideryColor();
    this.toggleChange();
  },
  computed: {
    inNavigationLayers() {
      const filteredLayers = [];
      _.each(this.customization.categories[this.selectedCategory].layers, (layer) => {
        if (layer.showInNavigation) {
          filteredLayers.push(layer);
        }
      });
      return filteredLayers;
    },
    customization() {
      return this.$store.state.customization;
    },
  },
  watch: {
    customization(newVal, oldVal) {
      _.each(this.customization.categories, (category) => {
        _.each(category.layers, (layer) => {
          if (layer.typeOfLayer == 'Color') {
            this.changeColor(
              layer.colorMapping,
              layer.toggles[layer.selectedToggle].colorAssignment
            );
          } else if (layer.typeOfLayer == 'Embroidery') {
            this.changeEmbroidery(
              layer.embroideryMapping,
              layer.toggles[layer.selectedToggle].name,
              layer.toggles[layer.selectedToggle].id
            );
          } else if (layer.typeOfLayer == 'Embroidery Color') {
            this.changeEmbroideryColor(
              layer.colorMapping,
              layer.toggles[layer.selectedToggle].colorAssignment
            );
          }
        });
      });
      // this.changeEmbroideryColor(
      //   this.customization.categories[this.selectedCategory].layers[this.selectedLayer]
      //     .colorMapping,
      //   this.customization.categories[this.selectedCategory].layers[this.selectedLayer].toggles[
      //     this.selectedToggle
      //   ].colorAssignment
      // );
    },
  },
  methods: {
    getLayer(layer) {
      if (layer.typeOfLayer == 'Color') {
        this.lastSelectedColorLayer = layer.id;
      }
    },
    changeDirection() {
      if (this.direction === 'Front') {
        this.direction = 'Back';
      } else {
        this.direction = 'Front';
      }
    },
    toggleChange() {
      this.calculatePriceNew();
    },
    async calculatePriceNew() {
      let price = 0;
      _.each(this.customization.categories, function (category, i) {
        _.each(category.layers, function (layer, j) {
          //  price = price + Number(layer.toggles[layer.selectedToggle].price);
        });
      });
      this.finalPrice = await price;
    },

    findIndexOfCategoryToggle(categoryId, categories) {
      var index = 0;
      _.each(categories, (category) => {
        _.each(category.layers, (layer) => {
          if (layer.typeOfLayer == 'Embroidery') {
            const obj = _.find(layer.embroideryMapping, (n) => {
              const obj = n.categoryId === categoryId;
              return obj;
            });
            if (obj && obj.flag) {
              index = layer.selectedToggle;
            }
          }
        });
      });
      console.log('log', index);
      return index;
    },

    // async calculatePrice(selectedCategory, selectedLayer, selectedToggle) {
    //   let price = 0;
    //   let currentPrice1 = 0;
    //   let totalPrice = 0;
    //   let togglePrice1 = 0;
    //   let togglePrice2 = 0;
    //   let currentPrice2 = 0;
    //   this.finalPrice = 0;

    //   _.each(this.customization.categories, function (category, i) {
    //     if (i == selectedCategory) {
    //       _.each(category.layers, function (layer, j) {
    //         if (j == selectedLayer) {
    //           _.each(layer.toggles, function (toggle, k) {
    //             if (k == selectedToggle) {
    //               currentPrice1 = layer.toggles[selectedToggle].price;
    //               togglePrice1 = currentPrice1 + togglePrice1;
    //             }
    //           });
    //         }
    //       });
    //       price = price + togglePrice1;
    //     } else {
    //       _.each(category.layers, (layer) => {
    //         currentPrice2 = layer.toggles[layer.selectedToggle].price;
    //         togglePrice2 = currentPrice2 + togglePrice2;
    //       });
    //       //totalPrice = totalPrice + togglePrice2;
    //     }
    //   });
    //   this.finalPrice = price + togglePrice2;
    // },
    changeEmbroideryColor(colorMappingArr, colorAssignmentArr) {
      _.each(colorAssignmentArr, (colorAssignmentObj) => {
        _.each(colorMappingArr, (colorMappingObj) => {
          if (colorAssignmentObj.colorMappingId == colorMappingObj.id) {
            let colorFilter = {};
            _.map(colorAssignmentObj.colorObject, (val, key) => {
              colorFilter[key] = val;
            });
            if (colorMappingObj.id != null) {
              if (
                colorFilter &&
                colorFilter.brightness == '0' &&
                colorFilter.hue == '0' &&
                colorFilter.brightness == '0'
              ) {
              } else {
                this.embColorObj[colorMappingObj.layerId] = colorFilter;
              }
            }
          }
        });
      });
    },
    changeColor(colorMappingArr, colorAssignmentArr) {
      colorAssignmentArr.forEach((colorAssignmentObj) => {
        colorMappingArr.forEach((colorMappingObj) => {
          if (colorAssignmentObj && colorAssignmentObj.colorMappingId === colorMappingObj.id) {
            const colorFilter = Object.fromEntries(
              Object.entries(colorAssignmentObj.colorObject || {}).filter(
                ([key, val]) => val !== undefined && val !== '0'
              )
            );

            if (colorMappingObj.id !== null && Object.keys(colorFilter).length > 0) {
              if (
                colorFilter.saturation === '0' &&
                colorFilter.hue === '0' &&
                colorFilter.brightness === '0'
              ) {
                this.showFilter[colorMappingObj.layerId] = false;
              } else {
                this.showFilter[colorMappingObj.layerId] = true;
                this.colorObj[colorMappingObj.layerId] = colorFilter;
              }
            }
          }
        });
      });
    },
    changeEmbroidery(embroideryMapping, embroideryName, embroideryId) {
      console.log('embroideryId', embroideryId);
      _.each(embroideryMapping, (embObj) => {
        if (embObj.flag == true) {
          _.each(this.customization.categories, (category) => {
            if (embObj.categoryId == category.id) {
              // category.selectedEmbroidery = embroideryName;
              category.selectedEmbroidery = embroideryId;
            }
          });
        } else {
          _.each(this.customization.categories, (category) => {
            if (embObj.categoryId == category.id) {
              category.selectedEmbroidery = '';
            }
          });
        }
      });
    },
    toggleSizeBtn(val) {
      this.selectedSize = val;
    },
    async getData() {
      const result = await service.getProduct(this.$route.params.id);
      this.customization = result.data;
      this.customizationsLoaded = true;
    },
    checkCurrentEmbFlagStatus(categoryId) {
      if (this.categories && this.categories.length > 0) {
        var flag = true;
        _.each(this.categories, (category) => {
          _.each(category.layers, (layer) => {
            if (layer.typeOfLayer == 'Embroidery') {
              const obj = _.find(layer.embroideryMapping, (n) => {
                const obj = n.categoryId === categoryId;
                return obj;
              });
              flag = obj.flag;
            }
          });
        });
        return flag;
      }
    },

    fetchBaseTypeIndexAsPerColorMapping(currentToggle, currentCategory) {
      const categoryId = currentCategory.id;
      const colorLayer = this.customization.categories
        .find((category) => category.id === categoryId)
        ?.layers.find((layer) => layer.typeOfLayer === 'Color');
      let colorToggle;
      if (colorLayer) {
        colorToggle = colorLayer.toggles[colorLayer.selectedToggle];
        localStorage.setItem('globalColorToggle', JSON.stringify(colorToggle || {}));
      }
      let index = 0;
      const globalColorToggle = JSON.parse(localStorage.getItem('globalColorToggle'));
      currentToggle.baseTypes.forEach((ImageBase, indexx) => {
        globalColorToggle?.baseTypesId?.forEach((toggle) => {
          if (
            toggle &&
            ImageBase.id === toggle.baseTypeId &&
            currentToggle._id === toggle.toggleId
          ) {
            index = indexx;
          }
        });
      });

      return index;
    },
    fetchBaseTypeIndexAsPerColorMappingEmb(currentToggle) {
      let finalIndex = 0;
      let colorToggle = {};

      for (const category of this.customization.categories) {
        for (const layer of category.layers) {
          if (layer.typeOfLayer === 'Color' || layer.typeOfLayer === 'Embroidery Color') {
            const selectedToggle = layer.toggles[layer.selectedToggle];
            colorToggle = selectedToggle || colorToggle;
          }
        }
      }

      for (const baseType of currentToggle.baseTypes) {
        for (const obj of colorToggle.baseTypesId) {
          if (baseType.id === obj.baseTypeId && currentToggle._id === obj.toggleId) {
            finalIndex = currentToggle.baseTypes.indexOf(baseType);
            // console.log('colorToggle in emb color', colorToggle);
            // console.log('baseType in emb color', baseType);
            // console.log('baseIndex in emb color', finalIndex);
            break;
          }
        }
      }
      return finalIndex;
    },
    textToIconGenerator(name) {
      // Create a new SVG element using SVG.js
      var draw = SVG().size('50', '50');

      // Calculate the center point of the SVG element
      var centerX = draw.width() / 2;
      var centerY = draw.height() / 2;

      // Split the name into two parts and insert a line break
      var textParts = name.split(' ');
      if (textParts.length > 1) {
        var text1 = textParts[0].toUpperCase();
        var text2 = textParts[1].toUpperCase();
      } else {
        var text1 = name.toUpperCase();
        var text2 = '';
      }

      // Create the two text elements with the modified text
      var textElement1 = draw.text(text1);
      var textElement2 = draw.text(text2);

      textElement1.font({
        family: 'Poppins, sans-serif',
        size: 10,
        anchor: 'middle',
        leading: '1.5em',
      });

      textElement2.font({
        family: 'Poppins, sans-serif',
        size: 10,
        anchor: 'middle',
        leading: '1.5em',
      });

      // Position the text elements at the center point of the SVG element
      var bbox1 = textElement1.bbox();
      var bbox2 = textElement2.bbox();
      textElement1.move(centerX - bbox1.width / 2, centerY - bbox1.height / 2 - bbox2.height / 2);
      textElement2.move(centerX - bbox2.width / 2, centerY - bbox2.height / 2 + bbox1.height / 2);

      // Get the SVG element as a string
      var svgString = draw.svg();

      // Convert the SVG string to a data URL
      var svgDataUrl = 'data:image/svg+xml;base64,' + window.btoa(svgString);

      // Return the data URL
      return svgDataUrl;
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/Scss/_main.scss';
.category {
  .model-name {
    font-size: 15px;
  }
  .category-name {
    font-size: 32px;
  }
  .layer {
    select {
      font-size: 15px;
    }
    font-size: 26px;
    margin-left: 20px;
    .toggle {
      .toggle-name {
        margin-top: 10px;
      }
      font-size: 20px;
      margin-left: 20px;
      .images-upload {
        font-size: 16px;
        margin-left: 20px;
        .image-upload-section {
          font-size: 14px;
          margin-left: 20px;
          width: 100px;
          height: 100px;
          border: 1px solid #ccc;
          display: inline-block;
          text-align: center;
          line-height: 100px;
        }
        .image-type {
        }
        .embroidery-type {
        }
      }
    }
  }
}
.size-direction-section {
  position: absolute;

  top: 0;
  bottom: 0;
  margin-top: 220px;
  margin-left: 20px;
  margin-bottom: auto;
}
.sidecard {
  background: white !important;
  min-height: 100% !important;
  height: 100vh;
}

.active-option-shade {
  border-radius: 100px !important;
  border: 2px solid #1f1f1f;
  height: 44px !important;
  width: 44px !important;
}
.inactive-option-shade {
  height: 30px !important;
  width: 30px !important;
  //margin-top: 8px !important;
  align-self: center;
}
.active-option-shade-color {
  border-radius: 100px !important;
  border: 2px solid #1f1f1f;
  height: 40px !important;
  width: 40px !important;
}
.rounded-color {
  height: 36px;
  width: 36px;
  border-radius: 50%;
}
.dark-btn {
  img {
    filter: brightness(5) !important;
    color: white !important;
  }
}

.model-image {
  width: 283px !important;
  height: 452px !important;
}
.model-image-plus {
  width: 336px !important;
  height: 452px !important;
}
.icon-image {
  position: relative;
  text-align: center !important;
  color: white !important;
}
.sub-icon-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white !important;
}
.v-text-field--outlined .v-label--active {
  display: none;
}
.custom-selection {
  width: 184px !important;
  background-color: #75614e;
  color: white !important;
  label {
    color: white !important;
  }

  i.v-icon.notranslate.mdi.mdi-menu-down.theme--light::before {
    margin-top: 13px;
    color: white;
  }
}
// .sub-option-img.text-center.px-1.py-1{
//   background-color: white !important;
//   border-radius: 150px !important;
//   border: 2px solid #75614e;
// }
.customize-card {
  width: 100%;
  background-color: #e5e5e5 !important;
}
.tabs-section {
  .v-tab--active {
    background: #75614e;
    color: white !important;
    border-radius: 6px 6px 6px 6px;
  }
  border: 1px solid #75614e;
  border-radius: 6px 6px 6px 6px;
  background-color: #efefef !important;
}
.tabs-slider-none {
  display: none;
}
.v-application .customize-footer .elevation-3 {
  box-shadow: 0px 0px 10px 7px #e5e5e5 !important;
}

.closeIcon {
  top: 1%;
  position: absolute;
  left: 2%;
}
.customize-title-sect {
  color: #434343;
  top: 1.5%;
  position: absolute;
  z-index: 999;
}

.side-icons {
  position: absolute;
  top: 20px;
  left: -1%;
  z-index: 100;
}
.side-icon-topmg {
  margin-top: -10px;
}
.customizeModel-section {
  background-image: url('../../assets/Images/custom-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100%;

  .customize-header {
    background-color: #ebe8e4 !important;
  }
  .custom-title {
    width: 80%;
  }
  .card-section {
    // background-image: url("../assets/Images/custom-bg.jpg");
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-position: center;
    // min-height: 850px !important;
  }
  .dark-btn {
    background-color: #75614e !important;
    color: $white !important;
  }
  .active-dark-btn {
    background-color: #75614e !important;
    color: white !important;
  }
  .v-btn:not(.v-btn--round).v-size--default {
    min-width: 100% !important;
    padding: 5px 0px 5px 0px !important;
  }

  .light-btn {
    color: #8d887f !important;
    background-color: transparent !important;
    box-shadow: none !important;
  }

  .back-btn {
    border-radius: 5px;
    min-width: 0;
  }
  .border-bottom {
    border: 1px solid;
  }
  .sub-option {
    overflow-x: scroll;
    background-color: #fff;
    height: 60px;
    margin-top: 2px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2), 0 0px 8px 0 rgba(0, 0, 0, 0.19);
    .selected-text {
      height: 24px;
      .text {
        line-height: 24px;
      }
    }
  }
  .selected-img {
    height: 55px !important;
    .v-btn-toggle > .v-btn.v-btn--active {
      background-color: #000 !important;
    }
    // border: 1px solid $light-grey;
    .v-item-group {
      .v-btn {
        border: 1px solid $light-brown !important;
        height: 55px !important;
        width: 55px !important;
        border-radius: 0px !important;
        margin: 0px;
        // background-color: #e5e4e0 !important;
      }
      .v-btn:before {
        background-color: transparent !important;
      }
      .v-btn.v-btn--active {
        border: 2px solid $light-black !important;
        height: 55px !important;
        width: 55px !important;
        border-radius: 0px !important;
      }
    }
    .v-sheet {
      background-color: $light-pink !important;
    }
  }
  .options {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    margin-top: 2px;

    // .v-sheet.v-card:not(.v-sheet--outlined) {

    //   box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2), 0 0px 8px 0 rgba(0, 0, 0, 0.19);
    // }
    // .customizeModel-section .options .v-sheet.v-card:not(.v-sheet--outlined){
    //   box-shadow: none !important;
    // }
    // .customizeModel-section .options .v-sheet.v-card:not(.v-sheet--outlined){
    //   box-shadow: 0px 0px 10px 7px #e5e5e5 !important;
    // }

    //   box-shadow: none !important;
    // }

    // background: #f8f4ee !important;
    // border-radius: 15px 15px 0px 0px !important;
    .v-item-group {
      .v-btn {
        text-transform: none;
        .v-btn__content {
          display: block;
        }
      }
      .v-btn:before {
        background-color: transparent !important;
      }
      .v-btn.v-btn--active {
        .v-btn__content {
          .title-text {
            color: #63504a;
          }
          .size-img {
            filter: brightness(0) !important;
          }
          font-weight: bold;
        }
      }
    }
    .title-text {
      color: #c8c8c8;
      text-align: center;
    }
  }
}
.options::-webkit-scrollbar {
  height: 4px;
}
.request-sent-container {
  height: 50%;
  width: 100%;
  img {
    position: absolute;
    top: 35%;
    left: 42%;
  }
}
.options::-webkit-scrollbar-thumb {
  background: #63504a;
  border-radius: 10px;
}
img.product-sub-image {
  height: 440px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.core-section {
  position: relative;
  width: 100%;
  height: 495px;
}
img.product-sub-image {
  position: absolute;
  transform: translate(-50%, 14%);
}
.dialog-close-icon {
  float: right;
}
.product-query-card {
  border-radius: 10px !important;
  background-color: #f9f6f0 !important;
}

text {
  display: flex;
}

.action {
  padding: 50px;
}
text1 {
  display: flex;
}
p {
  color: #fff;
}
.product-query-text {
  color: $light-brown;
}
.customize-btn {
  box-shadow: 1px 1px 5px #555;
}
.customize-color {
  border-radius: 100%;
}
.hide-completely {
  display: none !important;
}
// .closeIcon {
//   position: absolute !important;
//   right: 3%;
//   z-index: 999;
// }
.price-show {
  line-height: 58px;
  height: 10px;
}
.main-logo {
  margin-top: 500px;
}

.sub-option-img {
  height: 48px;
  width: 48px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    overflow: hidden;
  }
}

.inactive-option {
  background-color: white;
}
.active-style-option {
  background: black;
  color: white;
  height: 44px !important;
  width: 44px !important;
  margin-top: 1px !important;
  text-align: center;
  white-space: normal;
  font-weight: 600;
}
.inactive-style-option {
  border: 1.25px solid #1f1f1f;
  margin: 10px !important;
  background: white;
  color: black;
  height: 30px !important;
  width: 30px !important;
  margin-top: 12px !important;
  text-align: center;
  align-self: center !important;
  white-space: normal;
  font-weight: 600;
}
div.customizeModel-section .selected-img .v-sheet {
  background-color: white !important;
}
.btnGroup {
  position: absolute !important;
  left: 1%;
  z-index: 999;
  top: 6%;
}
.btnGroup-section {
  border: 1px solid #b3b2b2;
  border-radius: 30px 30px 30px 30px;
  background-color: #efefef !important;
  width: 40px !important;
}
.bottom-btn {
  margin: auto;
}

.reset-btn {
  text-transform: none !important;
  border-radius: 5px;
  border: 1px solid #a6a6a6;
  box-shadow: none !important;
}
.save-the-look-btn {
  text-transform: none !important;
  background-color: $black !important;
  color: $white !important;
  box-shadow: none !important;
}
.text-transform-none {
  text-transform: none !important;
}
.designed-by-sect {
  color: #75614e;
}
.back-btn-sect.v-btn {
  padding-left: 6px !important;
  padding-right: 6px !important;
}
.rounded-toggle-opt {
  border-radius: 4px;
}
.tabs-sect {
  .v-tabs-slider {
    display: none;
  }
  .v-tabs-bar {
    height: auto;
  }
}
.inactive-tab-sect {
  color: #656565 !important;
  text-transform: none !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.25);
}
.active-tab-sect {
  color: white !important;
  background-color: #75614e !important;
  text-transform: none !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.25);
}
.options-container {
  position: relative;
  width: 40px;
  height: 44px;
}
.size-img {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 15px;
  margin: auto;
  height: 40px;
  width: 40px;
}

.active-layer-section {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2), 0 0px 8px 0 rgba(0, 0, 0, 0.19) !important;
}
.inactive-layer-section {
  box-shadow: none !important;
}

@media only screen and (min-width: 960px) {
  .model-image {
    // margin-left: 2px !important;
    width: 283px !important;
    height: 452px !important;
  }
  .model-image-plus {
    width: 336px !important;
    height: 452px !important;
  }

  .closeIcon {
    top: 10%;
    position: absolute;
    left: 24px;
  }
  .model-content {
    padding-left: 124px !important;
  }
  .category {
    img {
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      right: 24%;
      left: 24%;
    }
  }

  .back-category {
    img {
      position: absolute;
      top: 105px;
    }
  }

  .realref {
    top: 16% !important;
    position: absolute !important;
    left: 10%;
    z-index: 999;
  }
  .core-section {
    position: relative;
    width: 100%;
    height: 640px;
  }
  img.product-sub-image {
    height: 540px;
    width: 350px;
  }
  .customizeModel-section .options .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 0px 4px 0 rgba(0, 0, 0, 0.19) !important;
  }
}
</style>
